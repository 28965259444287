@mixin breakpoint($point) {
	@if $point==sm {
		@media (min-width: 576px) {
			@content;
		}
	} @else if $point==max-sm {
		@media (max-width: 575px) {
			@content;
		}
	} @else if $point==md {
		@media (min-width: 768px) {
			@content;
		}
	} @else if $point==max-md {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point==lg {
		@media (min-width: 992px) {
			@content;
		}
	} @else if $point==max-lg {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $point==xl {
		@media (min-width: 1200px) {
			@content;
		}
	} @else if $point==max-xl {
		@media (max-width: 1199px) {
			@content;
		}
	} @else if $point==xxl {
		@media (min-width: 1400px) {
			@content;
		}
	} @else if $point==max-xxl {
		@media (max-width: 1399px) {
			@content;
		}
	}
}

@font-face {
	font-family: "Futura";
	src: url("./assets/fonts/futura medium bt.ttf") format(woff);
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: "Futura";
	src: url("./assets/fonts/Futura Bold font.ttf") format(ttf);
	font-style: normal;
	font-weight: 700;
}

:root {
	--title: #353535;
	--text: #353535;
	--body: #ffffff;
	--section: #fefeff;
	--white: #ffffff;
	--secondary: #e4e4e4;
	--base: #00a89d;
	--base-2: #6ecda9;
	--linear-1: linear-gradient(92deg, #6ec3a2 0%, #28b5a1 100.1%);
	--linear-2: linear-gradient(92deg, #54b18d 0%, #139885 100.1%);
	--body-fonts: "Futura", sans-serif;
	--heading-fonts: "Futura", sans-serif;
	--border: #e5e5e5;
}

body {
	background: var(--body);
	font-family: var(--body-fonts);
	color: var(--text);
	font-size: 20px;
	font-weight: 500;
	// font-weight: 500;
	line-height: 1.5;
	@include breakpoint(max-sm) {
		font-size: 18px;
	}
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--title);
	font-family: var(--heading-fonts);
	font-weight: 700;
}

h1 {
	font-size: 54px;
}
h2 {
	font-size: 36px;
	line-height: 42px;
}
h3 {
	font-size: 24px;
	line-height: 42px;
}
h4 {
	font-size: 22px;
}
h5 {
	font-size: 20px;
}
h6 {
	font-size: 18px;
}
@include breakpoint(max-md) {
	h1 {
		font-size: 34px;
	}
	h2 {
		font-size: 28px;
		line-height: 1.34;
	}
	h3 {
		font-size: 24px;
		line-height: 1.34;
	}
	h4 {
		font-size: 20px;
	}
	h5 {
		font-size: 18px;
	}
	h6 {
		font-size: 16px;
	}
}
@include breakpoint(max-sm) {
	h1 {
		font-size: 28px;
	}
	h2 {
		font-size: 26px;
	}
	h3 {
		font-size: 22px;
	}
	h4 {
		font-size: 20px;
	}
}
ul {
	padding: 0;
	margin: 0;
	li {
		list-style: none;
	}
}
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
a {
	text-decoration: none;
	transition: all ease 0.3s;
}
@include breakpoint(xxl) {
	.container {
		max-width: 1306px;
		padding-left: 15px;
		padding-right: 15px;
	}
}
.bg-section {
	background: var(--section) !important;
}
.bg-body {
	background: var(--body) !important;
}
/*Header*/
header {
	.container {
		@include breakpoint(xl) {
			max-width: 1459px;
		}
	}
	box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.17);
	background: var(--white);
}
.cmn-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	color: var(--white);
	padding: 8px 23px;
	border: none;
	outline: none;
	box-shadow: none;
	border-radius: 100px;
	text-transform: uppercase;
	position: relative;
	font-family: var(--heading-fonts);
	justify-content: center;
	text-align: center;
	&::before,
	&::after {
		content: "";
		inset: 0;
		border-radius: 100px;
		position: absolute;
		background: var(--linear-1);
		transition: all ease 0.3s;
	}
	&:before {
		opacity: 0;
		background: var(--linear-2);
	}
	* {
		position: relative;
		z-index: 1;
	}
	&:hover {
		color: var(--white);
		&::before {
			opacity: 1;
		}
		&::after {
			opacity: 0;
		}
	}
	@include breakpoint(max-sm) {
		font-weight: 500;
	}
}
.toggle-btn-group {
	background: var(--secondary);
	border-radius: 100px;
	display: flex;
	overflow: hidden;
	a {
		text-align: center;
		border-radius: 100px;
		color: var(--title);
		text-transform: uppercase;
		font-size: 16px;
		padding: 8px 16px;
		min-width: 86px;
		transition: all ease 0s;
		&.active {
			border-radius: 30px;
			font-weight: 700;
			position: relative;
			&::before {
				position: absolute;
				background: var(--linear-1);
				border-radius: 100px;
				inset: 0;
				content: "";
			}
		}
		flex-grow: 1;
		* {
			position: relative;
			z-index: 1;
		}
		&:nth-child(1) {
			&::before {
				animation: fadeInRight 0.4s;
			}
		}
		&:nth-child(2) {
			&::before {
				animation: fadeInLeft 0.4s;
			}
		}
	}
	@include breakpoint(md) {
		min-width: 221px;
	}
}
@keyframes fadeInRight {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0);
	}
}
@keyframes fadeInLeft {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(0);
	}
}
.header-wrapper {
	display: flex;
	align-items: center;
	padding: 18px 0;
	column-gap: 93px;
	.logo {
		width: 185px;
		display: block;
		img {
			width: 100%;
		}
	}
	@include breakpoint(max-xl) {
		column-gap: 50px;
	}
	@include breakpoint(max-lg) {
		column-gap: 40px;
		.logo {
			width: 150px;
		}
		.cmn-btn {
			font-size: 14px;
			font-weight: 500;
			padding-inline: 14px;
		}
		.toggle-btn-group {
			a {
				font-size: 14px;
				padding: 8px 14px;
				min-width: 0;
			}
		}
	}
	@include breakpoint(max-md) {
		column-gap: 20px;
		.logo {
			width: 100px;
		}
		.cmn-btn {
			font-size: 12px;
			padding-inline: 10px;
			line-height: 1.5;
		}
	}
	@include breakpoint(max-sm) {
		.cmn-btn {
			font-size: 10px;
			padding-inline: 7px;
		}
		.toggle-btn-group {
			a {
				font-size: 10px;
				padding: 8px;
			}
		}
	}
	@media screen and (max-width: 424px) {
		column-gap: 10px;
	}
}
/*Footer*/
footer {
	@include breakpoint(xl) {
		.container {
			max-width: 1270px;
		}
	}
}
.footer-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 45px 0;
	row-gap: 35px;
	column-gap: 20px;
	border-top: 1px solid #e6e6e6;
	.widget-about {
		width: 100%;
		max-width: 264px;
		color: #000;
		text-transform: capitalize;
		.footer-logo {
			display: block;
			max-width: 200px;
			img {
				width: 100%;
			}
			margin-bottom: 12px;
		}
		font-size: 13px;
		font-weight: 500;
		line-height: 19.5px;
	}
	.widget-social {
		width: 100%;
		max-width: 120px;
	}
	.title {
		font-size: 21px;
		font-style: normal;
		font-weight: 500;
		line-height: 19.5px;
		color: var(--base);
		margin-bottom: 24px;
	}
	.widget-links {
		.links {
			display: flex;
			flex-direction: column;
			gap: 10px;
			li {
				a {
					color: var(--title);
					font-size: 15px;
					font-style: normal;
					font-weight: 500;
					line-height: 19.5px;
					&:hover {
						color: var(--base-2);
					}
				}
			}
		}
	}
	@include breakpoint(max-sm) {
		column-gap: 0;
		.footer-widget {
			width: 50%;
			&.widget-about {
				width: 100%;
				max-width: 100%;
				.footer-logo {
					max-width: 140px;
				}
			}
		}
	}
}
.social-icons {
	display: flex;
	flex-wrap: wrap;
	column-gap: 21px;
	row-gap: 14px;
	li {
		a {
			color: var(--title);
			&:hover {
				color: var(--base-2);
			}
		}
	}
}
.footer-bottom {
	color: #000;
	font-size: 10px;
	font-weight: 500;
	line-height: 19.5px;
	padding-top: 17px;
	padding-bottom: 51px;
	text-transform: capitalize;
	@include breakpoint(max-sm) {
		font-size: 13px;
		padding-bottom: 30px;
		padding-top: 0;
	}
}
/*Banner*/
.banner-section {
	padding: 260px 0 180px;
	position: relative;
	&::before {
		content: "";
		inset: 0;
		position: absolute;
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0.3) 0%,
			rgba(0, 0, 0, 0.2) 100%
		);
	}
	.container {
		position: relative;
		z-index: 1;
	}
	@include breakpoint(max-xxl) {
		padding: 200px 0 140px;
	}
	@include breakpoint(max-md) {
		padding: 140px 0;
	}
}
.banner-content {
	text-align: center;
	.title {
		color: var(--base-2);
		font-size: 48px;
		font-style: normal;
		font-weight: 700;
		line-height: 64px;
		text-transform: uppercase;
		min-height: 128px;
		margin-bottom: 118px;
	}
	.app-btn {
		display: inline-block;
		img {
			// max-width: 100%;
			height: 40px;
		}
	}
	@include breakpoint(max-xxl) {
		.title {
			min-height: 0;
		}
	}
	@include breakpoint(max-lg) {
		.title {
			margin-bottom: 80px;
		}
	}
	@include breakpoint(max-md) {
		.title {
			font-size: 40px;
		}
	}
	@include breakpoint(max-sm) {
		.title {
			font-size: 7.5vw;
			line-height: 1.45;
			margin-bottom: 40px;
		}
	}
}
@include breakpoint(sm) {
	.banner-btn {
		font-size: 20px;
		padding: 14px 23px 12px;
	}
}
.about-section {
	padding: 117px 0 92px;
	.container {
		@include breakpoint(xl) {
			max-width: 1260px;
		}
	}
	@include breakpoint(max-md) {
		padding: 80px 0;
	}
}
.about-content {
	// max-width: 590px;
	.title {
		color: var(--base);
		margin-bottom: 23px;
	}
	font-weight: 500;
	p {
		margin-bottom: 30px;
	}
}
.download-btn {
	height: 50px;
	padding-inline: 25px;
}
.about-section-2 {
	.container {
		@include breakpoint(xl) {
			max-width: 1334px;
		}
	}
	@include breakpoint(max-md) {
		padding: 80px 0;
	}
}
.popup {
	// position: fixed;
	// top: 0;
	// left: 0;
	// width: 100vw;
	// height: 100vh;
	// z-index: 999;
	// overflow-y: auto;
	// @include breakpoint(sm) {
	// display: flex;
	// flex-wrap: wrap;
	// align-items: center;
	// justify-content: space-between;
	// }

	.popup-inner {
		position: relative;
		padding: 30px 20px 30px 50px;
		width: 100%;
		background: var(--white);
		margin: auto;
		z-index: 9;
		&::after {
			position: absolute;
			content: "";
			inset: 0;
			background: var(--white);
		}
		&::before {
			background: linear-gradient(92deg, #54b18d 0%, #139885 100.1%);
			position: absolute;
			content: "";
			inset: 42px 42px -42px -37px;
			z-index: -2;
			@include breakpoint(max-sm) {
				display: none !important;
			}
		}
		@include breakpoint(max-lg) {
			padding: 30px;
			width: calc(100% - 60px);
			&::before {
				left: -22px;
				bottom: -22px;
			}
		}
		@include breakpoint(max-sm) {
			width: 100%;
			padding: 30px 0 0;
			.close-btn {
				background: var(--section);
				border-radius: 50%;
			}
		}
	}
	.backdrop {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(#000000, 0.7);
	}
	transition: all ease 0.3s;
	&:not(.active) {
		opacity: 0;
		visibility: hidden;
	}
	.img {
		position: relative;
		z-index: 99;
		img {
			height: calc(100vh - 30px);
			object-fit: contain;
			object-position: center center;
		}
	}
	@include breakpoint(max-sm) {
		background: var(--body) !important;
		.backdrop {
			display: none;
		}
	}
}
.no-gutter {
	padding: 0;
	border: none;
	outline: none;
	box-shadow: none;
	background: transparent;
}
.close-btn {
	position: absolute;
	right: 15px;
	top: 15px;
	z-index: 999;
}
.qr-item {
	position: relative;
	z-index: 9;
	font-weight: 500;
	@include breakpoint(sm) {
		display: flex !important;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
	.title {
		color: var(--base);
	}
	.right {
		position: relative;
		&::before {
			position: absolute;
			content: "";
			width: 4px;
			height: 76%;
			top: 12%;
			left: -30px;
			background: var(--base);
		}
		max-width: 249px;
		@include breakpoint(max-lg) {
			max-width: 220px;
		}
	}
}
.feature-item {
	text-align: center;
	max-width: 326px;
	margin-inline: auto;
	line-height: 24px;
	img {
		max-width: 122px;
		margin-bottom: 17px;
	}
	.subtitle {
		margin-bottom: 23px;
		color: var(--white);
	}
	p {
		margin: 0;
	}
	@include breakpoint(max-md) {
		img {
			max-width: 90px;
			margin-bottom: 23px;
		}
		.subtitle {
			font-size: 22px;
			line-height: 1.4;
		}
		font-size: 16px;
	}
}
.feature-section {
	position: relative;
	.feature-section-inner {
		padding: 105px 0px 118px;
		color: var(--white);
		position: relative;
		@include breakpoint(max-lg) {
			padding: 135px 0px;
		}
	}
}
.faqs-section {
	padding: 92px 0 93px;
	overflow: hidden;
}
.accordion-item {
	background: var(--section) !important;
	border: none;
	border-radius: 0 !important;
	color: var(--text);
	padding-inline: 0;
	border-bottom: 1px solid #e6e6e6;
	a {
		text-decoration: underline;
	}
	.accordion-header {
		border-radius: 0 !important;
		.accordion-button {
			padding-inline: 0;
			padding-block: 11px;
			min-height: 75px;
			box-shadow: none;
			background: transparent !important;
			// border-radius: 0 !important;
			color: var(--title);
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			white-space: initial;
			word-break: break-all;
			border-radius: 8px !important;
			@include breakpoint(md) {
				font-size: 20px;
				padding: 24px 0;
			}
			&::after {
				display: none;
			}
			.subtxt {
				font-weight: 500;
				font-size: 16px;
				line-height: 24px;
				color: var(--text);
			}
			&.active[aria-expanded="true"] {
				border-radius: 8px 8px 0 0 !important;
			}
			@include breakpoint(max-md) {
				flex-wrap: wrap;
				// .subtxt {
				// 	width: 100%;
				// }
				min-height: 65px;
				.icon {
					position: absolute;
					left: 7px;
					top: 14px;
				}
			}
			.plus,
			.minus {
				flex-grow: 1;
				text-align: right;
				position: absolute;
				right: 5px;
				top: 18px;
				display: inline-flex;
				@include breakpoint(md) {
					top: 25px;
					right: 12px;
				}
				@include breakpoint(max-md) {
					svg {
						width: 20px;
						height: 20px;
					}
				}
			}
			padding-right: 30px;
		}
	}
	.accordion-body {
		padding: 0 34px 24px 0;
		border-radius: 0 !important;
		p {
			margin: 0;
		}
		@include breakpoint(xl) {
			font-size: 20px;
			font-weight: 400;
			line-height: 24px;
			font-weight: 500;
		}
	}
	@include breakpoint(max-xl) {
		font-size: 16px;
		.accordion-header {
			.accordion-button {
				font-size: 16px;
			}
		}
	}
}
.text-base {
	color: var(--base) !important;
}
.faq-wrapper {
	display: flex;
	flex-wrap: wrap-reverse;
	gap: 68px;
	justify-content: space-between;

	.faqs-area {
		width: 100%;
		max-width: 741px;
	}
	.img-area {
		width: 0;
		flex-grow: 1;
		@include breakpoint(xl) {
			margin-top: 50px;
		}
		img {
			max-width: 521px;
		}
	}
	&.reverse {
		flex-direction: row-reverse;
		.img-area {
			img {
				max-width: 378px;
			}
			@include breakpoint(xl) {
				margin-top: 0px;
			}
		}
		.faqs-area {
			@include breakpoint(xl) {
				margin-top: 30px;
			}
		}
	}

	@include breakpoint(max-xxl) {
		.img-area img {
			max-width: 480px;
		}
		&.reverse {
			.img-area {
				img {
					max-width: 100%;
				}
			}
		}
		.faqs-area {
			max-width: 600px;
		}
	}
	@include breakpoint(max-xl) {
		row-gap: 30px;
		.faqs-area {
			max-width: 600px;
		}
		.faq-title {
			margin-bottom: 10px;
		}
	}
	@include breakpoint(max-lg) {
		.img-area {
			width: 100%;
			img {
				max-width: 100%;
			}
		}
		.faqs-area {
			max-width: 100%;
		}
	}
}
.accordion-button.active {
	.plus {
		display: none !important;
	}
}
.accordion-button:not(.active) {
	.minus {
		display: none !important;
	}
}
.faq-title {
	margin-bottom: 29px;
	color: var(--base);
}
.near-deals-section {
	padding-top: 90px;
}
.near-deal-content {
	.title {
		color: var(--base);
		margin-bottom: 26px;
	}
	text-align: center;
	margin-bottom: 52px;
}
.count-item {
	font-weight: 500;
	text-align: center;
	h2 {
		color: var(--base);
		font-size: 43px;
		margin-bottom: 23px;
	}
	@include breakpoint(max-sm) {
		h2 {
			margin-bottom: 0;
		}
	}
}
.we-are-here-section {
	padding-top: 92px;
}
.we-are-here-title {
	color: var(--base);
	text-align: center;
	margin-bottom: 61px;
	@include breakpoint(max-md) {
		svg {
			width: 130px;
			height: unset;
		}
	}
	@include breakpoint(max-sm) {
		svg {
			width: 110px;
		}
		margin-bottom: 45px;
	}
}
.we-are-wrapper {
	display: flex;
	gap: 30px;
	justify-content: space-between;
	.img {
		width: 468px;
		img {
			max-width: 100%;
		}
	}
	.cont {
		max-width: 710px;
		width: 0;
		flex-grow: 1;
		.title {
			margin-bottom: 12px;
		}
		font-weight: 500;
	}
	@include breakpoint(max-lg) {
		.img {
			width: 350px;
		}
	}
	@include breakpoint(max-md) {
		flex-wrap: wrap-reverse;
		.cont,
		.img {
			width: 100%;
		}
	}
}
.how-it-works {
	margin-top: -84px;
	position: relative;
	.swiper-pagination {
		display: flex;
		justify-content: center;
		position: relative;
		inset: unset;
		.swiper-pagination-bullet {
			width: 32px;
			height: 32px;
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: 20px;
			color: var(--base);
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 1;
			&::before {
				content: "1";
			}
			background: transparent;
			&.swiper-pagination-bullet-active {
				background: #f3f3f3;
			}
			&:nth-child(2) {
				&::before {
					content: "2";
				}
			}
			&:nth-child(3) {
				&::before {
					content: "3";
				}
			}
			&:nth-child(4) {
				&::before {
					content: "4";
				}
			}
			&:nth-child(5) {
				&::before {
					content: "5";
				}
			}
			&:nth-child(6) {
				&::before {
					content: "6";
				}
			}
			&:nth-child(7) {
				&::before {
					content: "7";
				}
			}
			&:nth-child(8) {
				&::before {
					content: "8";
				}
			}
			&:nth-child(9) {
				&::before {
					content: "9";
				}
			}
			&:nth-child(9) {
				&::before {
					content: "9";
				}
			}
			&:nth-child(10) {
				&::before {
					content: "10";
				}
			}
			&:nth-child(11) {
				&::before {
					content: "11";
				}
			}
			&:nth-child(12) {
				&::before {
					content: "12";
				}
			}
			&:nth-child(13) {
				&::before {
					content: "13";
				}
			}
		}
	}
	.swiper-slide:not(.swiper-slide-visible) {
		opacity: 0 !important;
		.how-item {
			transform: scale(0.4) !important;
		}
	}
}
.how-item {
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 77px;
	transition: all ease 0.6s;
	.img {
		width: 100%;
		max-width: 308px;
		img {
			width: 100%;
		}
	}
	.cont {
		max-width: 465px;
		padding-top: 50px;
		width: 100%;
		font-weight: 500;
		.title {
			margin-bottom: 20px;
		}
		p {
			margin-bottom: 65px;
		}
		.cmn-btn {
			display: inline-block;
		}
	}
	@include breakpoint(max-lg) {
		gap: 35px;
	}
	@include breakpoint(max-md) {
		column-gap: 0;
		.img {
			max-width: 220px;
		}
		.cont {
			font-size: 18px;
			p {
				margin-bottom: 35px;
			}
			.cmn-btn {
				font-size: 14px;
			}
		}
	}

	@include breakpoint(max-sm) {
		flex-wrap: wrap;
		gap: 0;
		padding-bottom: 40px;
		text-align: center;
		.cont {
			padding-top: 0;
			width: 100%;
			.cmn-btn {
				margin: 0 auto;
			}
		}
	}
}
.swiper-action-buttons {
	display: flex;
	justify-content: space-between;
	max-width: 500px;
	margin: 0 auto;
	margin-top: -40px;
	position: relative;
	z-index: 2;
	height: 0px;
}
