/* BlogSingle.css */

/* General container styling */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Back link styling */
.text--base {
    color: #00a99d;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
}

.text--base:hover {
    text-decoration: underline;
}

/* Section title styling */
.section-title {
    margin-bottom: 20px;
}

.section-title h2 {
    font-size: 2rem;
    color: #000000;
}

.section-title p {
    font-size: 1.rem;
    color: #666;
}

/* Blog content styling */
.blog-single-section {
    padding: 40px 0;
    position: relative;
}

.blog-single-section .shape {
    position: absolute;
    z-index: -1;
}

.blog-content {
    display: flex;
    flex-direction: column;
}

.blog-content img {
    width: 100%;
    border-radius: 10px;
}

.blog-text p {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
    margin-bottom: 15px;
}




:root {
   
	--title: #353535;
	--text: #353535;
	--body: #ffffff;
	--section: #fefeff;
	--white: #ffffff;
	--secondary: #e4e4e4;
	--base: #00a89d;
	--base-2: #6ecda9;
	--linear-1: linear-gradient(92deg, #6ec3a2 0%, #28b5a1 100.1%);
	--linear-2: linear-gradient(92deg, #54b18d 0%, #139885 100.1%);
	--body-fonts: "Futura", sans-serif;
	--heading-fonts: "Futura", sans-serif;
	--border: #e5e5e5;
    

    --gradient: linear-gradient(
        85.2deg,
        var(--base) -13.39%,
        var(--base-2) 125.15%
    );
    --gradient-2: linear-gradient(
        85.2deg,
        var(--base-2) -13.39%,
        var(--base) 125.15%
    );

}









.cmn-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	color: var(--white);
	padding: 8px 23px;
	border: none;
	outline: none;
	box-shadow: none;
	border-radius: 100px;
	text-transform: uppercase;
	position: relative;
	font-family: var(--heading-fonts);
	justify-content: center;
	text-align: center;
	&::before,
	&::after {
		content: "";
		inset: 0;
		border-radius: 100px;
		position: absolute;
		background: var(--linear-1);
		transition: all ease 0.3s;
	}
	&:before {
		opacity: 0;
		background: var(--linear-2);
	}
	* {
		position: relative;
		z-index: 1;
	}
	&:hover {
		color: var(--white);
		&::before {
			opacity: 1;
		}
		&::after {
			opacity: 0;
		}
	}
	@include breakpoint(max-sm) {
		font-weight: 500;
	}
}



.shape {
	position: absolute;
	top: 0;
	right: calc(50% + 355px);
	opacity: 0.5;
	&.odd {
		left: calc(50% + 355px);
		right: 0;
	}
}
.brands-inner-section {
	.section-gap {
		padding: 55px 0;
		@include breakpoint(max-lg) {
			padding: 0;
			&:not(:last-child) {
				padding-bottom: 40px;
			}
		}
		&:first-child {
			padding-top: 0;
		}
		&:last-child {
			padding-bottom: 0;
		}
	}
}





.subscribe-section {
	padding: 67px 0;
	position: relative;
	&::before {
		inset: 0;
		content: "";
		position: absolute;
		background: rgba(#000000, 0.4);
	}
}
.subscribe-content {
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.1);
	border-radius: 20px;
	max-width: 751px;
	margin: 0 auto;
	padding: 36px 20px;
	@include breakpoint(lg) {
		padding-bottom: 56px;
	}
	.content {
		max-width: 518px;
		margin: 0 auto;
	}
}




	
